import "./titleStyles.css";

const Title = () => {
  return (
    <>
      <div className="contentSection__title">
        <h1>I am here for the tech, tek</h1>
      </div>
      <div className="contentSection__subtitle">
        <p>The first ERC404 with reflection fee.</p>
      </div>
      <div className="contentSection__info">
        <p>CA: 0xEC728FF7387523C803fF2dB66cEa437977A2D75E</p>
      </div>
    </>
  );
};

export default Title;
