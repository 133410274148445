import './App.css';
import Content from './components/Content';
import Footer from './components/Footer';
import Header from "./components/Header";
import About from "./components/About";
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

const config = getDefaultConfig({
  appName: 'EggFrens',
  projectId: 'YOUR_PROJECT_ID',
  chains: [mainnet],
  ssr: true, // If your dApp uses server side rendering (SSR)
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme()}>
          <main>
            <div className='antipishing'>
              <p>PHISHING WARNING: please make sure you're visiting https://eggfrens.com - check the URL carefully.</p>
            </div>
            <Header />
            <Content />
            <About />
            <Footer />
          </main>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider >
  );
}

export default App;
