import "./contentStyles.css";
import Title from "../Title";

const Content = () => {
  return (
    <>
      <section className="contentSection">
        <div className="contentSection__wrapper">
          <Title />
          <div className="contentSection__bg">
            <div className="contentSection__bg-logo">
              <img src="/logo.png" alt="" />
            </div>
            <div className="contentSection__bg-image">
              <img src="/mainImg.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
