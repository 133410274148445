import "./aboutStyles.css";

const About = () => {
    return (
        <>
            <section className="aboutSection" id="about">
                <div className="aboutSection__wrapper">
                    <div className="imgAboutL">
                        <img src="./Welcome_512x512.gif" alt="" />
                    </div>
                    <div className="AboutContentR">

                        <h2>About:</h2>
                        <p>
                            404🥚Eggs Friends is a memetic collection featuring 4040 NFTs of eggs with faces of famous personalities in the crypto universe.
                        </p>
                        <br />
                        <p>
                            🧑‍💻 TEK <br />
                            ✅ First collection of Erc 404 NFTs with trait evolution based on market cap<br />
                            ✅ First collection of Erc 404  with double token deflation (Real NFT burn + token burn)<br />
                            ✅ First ERC 404 token with fee. 3% Tax<br />
                        </p>

                    </div>
                </div>
            </section>

            <section className="aboutSection" id="about">
                <div className="aboutSection__wrapper">

                    <div className="imgAboutR">
                        <img src="./8.png" alt="" />
                    </div>
                    <div className="AboutContentL">
                        <h2>Project Details:</h2>
                        <p><b>Personalities:</b> 404 Exclusive personalities</p>
                        <p><b>Supply:</b> 4040 tokens + NFTs</p>

                        <br />
                        <p><b>NFT Evolutions</b> are triggered based on the project's market cap:</p>
                        <p>
                            🥚1st evolution: 1 million MC <br />
                            🥚2nd evolution: 10 million MC <br />
                            🥚3rd evolution: 50 million MC <br />
                            🥚4th evolution: 100 million MC <br />
                            🥚5th evolution: 500 million MC <br />
                            🥚6th evolution: 1 Billion MC <br /><br />

                            ⏰ Evolutions will be triggered 24 hours after the market cap stabilizes
                        </p>
                    </div>
                </div>
            </section>

            <section className="aboutSection" id="about">
                <div className="aboutSection__wrapper">
                    <div className="AboutFooter">
                        <p>Created by <a href="https://t.me/EggFriends404" target='_blank' rel="noreferrer">@404EggFrens</a> copyright © 2024</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
