import "./footerStyles.css";

const Footer = () => {
  return (
    <footer>
    <a href="#about">
        <button className="footerButton__about">ABOUT</button>
    </a>
    <a href="https://opensea.io/collection/egg-frens">
        <button className="footerButton__buy"> 
            <img src="/arrow.svg" alt=""/>
            <span className="footerButton__buy-first">BUY 404FRENS ON OPENSEA</span>
            <span className="footerButton__buy-second">BUY ON OPENSEA</span>
        </button>
    </a>
    <a href="https://app.uniswap.org/swap?inputCurrency=ETH&amp;outputCurrency=0xEC728FF7387523C803fF2dB66cEa437977A2D75E">
        <button className="footerButton__buy"> 
            <img src="/arrow.svg" alt=""/>
            <span className="footerButton__buy-first">BUY 404FRENS ON UNISWAP</span>
            <span className="footerButton__buy-second">BUY ON UNISWAP</span>
        </button>
    </a>
</footer>
  );
};

export default Footer;
