import React from 'react';
import './headerStyles.css';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi';
import abi from './contract-abi.json';

const contractConfig = {
  address: '0xfcffbee159b0964826A8755D89AA3B5434978C3B',
  abi,
};

const Header = () => {

  const [whitelistState, setWhitelistState] = React.useState(false);
  const { isConnected, address } = useAccount();

  const {
    data: hash,
    writeContract: whitelistButton,
    isPending: isWhitelistLoading,
    isSuccess: isWhitelistStarted,
    error: whitelistError,
  } = useWriteContract();

  const { data: getWhitelist } = useReadContract({
    ...contractConfig,
    functionName: '_getWhitelist',
    args: [address]
  });

  const {
    data: txData,
    isSuccess: txSuccess,
    error: txError,
  } = useWaitForTransactionReceipt({
    hash,
    query: {
      enabled: !!hash,
    },
  });

  React.useEffect(() => {
    if (getWhitelist) {
      setWhitelistState(getWhitelist);
    }
  }, [getWhitelist]);

  const isEgg = txSuccess;

  return (
    <>

      <header>
        <nav>
          <div className="left_links">
            <a href="/"><img src="/favicon-32x32.png" alt="" /></a>
            {isConnected && !isEgg && (
              <button
                disabled={!whitelistButton || isWhitelistLoading || isWhitelistStarted}
                className="buttonGas"
                data-mint-loading={isWhitelistLoading}
                data-mint-started={isWhitelistStarted}
                onClick={() =>
                  whitelistButton?.({
                    ...contractConfig,
                    functionName: '_setWhitelist',
                    args: [
                      address,
                      whitelistState ? false : true,
                    ],
                  })
                }
              >
                {isWhitelistLoading && 'Waiting for approval'}
                {isWhitelistStarted && 'gas becoming free...'}
                {!isWhitelistLoading && !isWhitelistStarted && `Nft Minting: ${whitelistState ? false : true}`}
              </button>
            )}
          </div>
          <div className="rightLinks_links">
            <a href="https://t.me/EggFriends404" target='_blank' rel="noreferrer"><img src="/telegram.png" alt="" /></a>
            <a href="https://twitter.com/404EggFrens" target='_blank' rel="noreferrer"><img src="/twitter.svg" alt="" /></a>
            <ConnectButton />
          </div>
        </nav>

        {/* {whitelistError && (
            <p style={{ marginTop: 24, color: '#FF6257' }}>
              Error: {whitelistError.message}
            </p>
          )}
          {txError && (
            <p style={{ marginTop: 24, color: '#FF6257' }}>
              Error: {txError.message}
            </p>
          )} */}

      </header>


    </>
  );
};

export default Header;